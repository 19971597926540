import React, { useState, useEffect } from 'react';
import Separator from '~/components/Separator';
import InputSelect from '~/components/Inputs/InputSelect';
import { Col, Row } from 'react-bootstrap';
import Tooltip from '@atlaskit/tooltip';
import { TooltipBreakline } from '~/pages/RegraDeEmpresa/styles';
// eslint-disable-next-line import/no-unresolved
import {
  Select,
  GrupoEmpresa,
  tipoCustos,
  StatusPdv,
  tipoMargens,
} from '~/pages/RegraDeEmpresa/protocols';
import { useRegraEmpresa } from '~/pages/RegraDeEmpresa/RegraDeEmpresaContext';
import { LimiteCredito } from './LimiteCredito';

import api from '~/services/api';
import InputPercent from '~/components/Inputs/InputPercent2';
import { transformAsCurrency } from '~/utils/functions';
import { FaFlask } from 'react-icons/fa';
import { toast } from 'react-toastify';

export const AbaGeral: React.FC = () => {
  // States da aba geral
  const [codStatusPdvs, setCodStatusPdvs] = useState<Select[]>([]);
  const {
    formRegraEmpresa,
    handleFormRegraEmpresa,
    isUpdate,
    inicializado,
    formTela,
  } = useRegraEmpresa();

  const getGrupos = async () => {
    if (!isUpdate) {
      const res = await api.get('/regraempresa/grupo/0');
      const { data } = res.data;
      data.map((reg: GrupoEmpresa) => {
        return {
          value: reg.cod_grupo_empresa,
          label: reg.des_grupo_empresa,
        };
      });
    } else {
      const res = await api.get('/regraempresa/grupo/1');
      const { data } = res.data;
      data.map((reg: GrupoEmpresa) => {
        return {
          id: reg.cod_grupo_empresa,
          label: `${reg.des_grupo_empresa} (${reg.des_lojas})`,
        };
      });
    }
  };

  async function getStatusPDV() {
    try {
      const res = await api.get(`statuspdv/tipo/0`);
      const { data, success, message } = res.data;

      if (!success) {
        throw new Error(message);
      }

      const regs = data.map((reg: StatusPdv) => {
        return {
          value: reg.cod_status_pdv,
          label: `${reg.cod_status_pdv} - ${reg.des_status_pdv}`,
        };
      });
      setCodStatusPdvs(regs);
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    getStatusPDV();
    getGrupos();
  }, []);

  function getFormula() {
    const tipo = Number(formRegraEmpresa.tipo_margem.value.value) ?? null;
    switch (tipo) {
      case 0:
        return 'CustoRep*(1+(Margem/100))';
      case 1:
        return `
          CustoSImposto:
          (CustoSICMSE - (CustoRep*((PIS+Cofins)/100.0)))
          ITEM COM PIS/COFINS:
          CustoSImposto/(1-(ICMSS+PIS+Cofins+DespOp/100)-(Margem/100))
          ITEM SEM PIS/COFINS:
          CustoSICMS/(1-(ICMSS+DespOp/100)-(Margem/100))
        `;
      case 2:
        return 'CustoRep/(1-(Margem/100))';
      case 3:
        return `
          CustoSImposto:
          (CustoSICMSE - (CustoRep*((PIS+Cofins)/100.0)))
          ITEM COM PIS/COFINS:
          CustoSImposto/(1-(ICMSS+PIS+Cofins/100)-(Margem/100))
          ITEM SEM PIS/COFINS:
          CustoSICMS/(1-(ICMSS/100)-(Margem/100))
        `;
      default:
        return 'Selecione um tipo de margem';
    }
  }

  return (
    <>
      <Row>
        <Col sm={12} md={12}>
          <Separator labelText="Custos" />
        </Col>
        <Col sm={12} md={5}>
          <InputSelect
            label="Margem p/ Cálculo de Preço de Venda"
            onChange={(value: Select) => {
              formTela.setValue('tipo_margem', value);
              handleFormRegraEmpresa({
                ...formRegraEmpresa,
                tipo_margem: {
                  ...formRegraEmpresa.tipo_margem,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
            options={tipoMargens}
            isRequired={formRegraEmpresa.tipo_margem.isRequired}
            setInvalid={formRegraEmpresa.tipo_margem.isInvalid}
            iniInicializado={inicializado}
            value={
              tipoMargens.find((item: Select) => {
                return item.value === formRegraEmpresa.tipo_margem.value.value;
              }) ?? {
                value: undefined,
                label: '- Selecione a Margem -',
              }
            }
          />
        </Col>
        <Col sm={12} md={1}>
          <div style={{ marginTop: '32px' }}>
            <Tooltip
              component={TooltipBreakline}
              position="top"
              content={getFormula}
            >
              <FaFlask size={30} color="#8850BF" className="formula-icon" />
            </Tooltip>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <InputSelect
            label="Custo Médio Baseado no Custo"
            onChange={(value: Select) => {
              formTela.setValue('tipo_custo', value);
              handleFormRegraEmpresa({
                ...formRegraEmpresa,
                tipo_custo: {
                  ...formRegraEmpresa.tipo_custo,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
            options={tipoCustos}
            isRequired={formRegraEmpresa.tipo_custo.isRequired}
            setInvalid={formRegraEmpresa.tipo_custo.isInvalid}
            iniInicializado={inicializado}
            value={
              tipoCustos.find((item: Select) => {
                return item.value === formRegraEmpresa.tipo_custo.value.value;
              }) ?? {
                value: undefined,
                label: '- Selecione o Custo -',
              }
            }
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Separator labelText="Atualização de Custos" />
          <Row>
            <Col sm={12}>
              <InputPercent
                label="% Tolerância Até Emitir Aviso"
                casas=".01"
                min={0}
                placeholder="0,00%"
                onChange={(value: string) => {
                  formTela.setValue('per_tol_custo', value);
                  handleFormRegraEmpresa({
                    ...formRegraEmpresa,
                    per_tol_custo: {
                      ...formRegraEmpresa.per_tol_custo,
                      value: value?.replace('-', ''),
                      isInvalid: transformAsCurrency(value) === 0,
                    },
                  });
                }}
                isRequired={formRegraEmpresa.per_tol_custo.isRequired}
                setInvalid={formRegraEmpresa.per_tol_custo.isInvalid}
                iniInicializado={inicializado}
                value={formRegraEmpresa.per_tol_custo.value}
              />
            </Col>
            <Col sm={12}>
              <InputPercent
                label="% Tolerância Até Emitir Bloqueio"
                casas=".01"
                placeholder="0,00%"
                onChange={(value: string) => {
                  formTela.setValue('per_bloq_custo', value);
                  handleFormRegraEmpresa({
                    ...formRegraEmpresa,
                    per_bloq_custo: {
                      ...formRegraEmpresa.per_bloq_custo,
                      value: value?.replace('-', ''),
                      isInvalid: transformAsCurrency(value) === 0,
                    },
                  });
                }}
                isRequired={formRegraEmpresa.per_bloq_custo.isRequired}
                setInvalid={formRegraEmpresa.per_bloq_custo.isInvalid}
                iniInicializado={inicializado}
                value={formRegraEmpresa.per_bloq_custo.value}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={6}>
          <Separator labelText="Atualização Preço de Venda" />
          <Row>
            <Col sm={12}>
              <InputPercent
                label="% Tolerância Até Emitir Aviso"
                casas=".01"
                placeholder="0,00%"
                onChange={(value: string) => {
                  formTela.setValue('per_tol_venda', value?.replace('-', ''));
                  handleFormRegraEmpresa({
                    ...formRegraEmpresa,
                    per_tol_venda: {
                      ...formRegraEmpresa.per_tol_venda,
                      value: value?.replace('-', ''),
                      isInvalid: transformAsCurrency(value) === 0,
                    },
                  });
                }}
                isRequired={formRegraEmpresa.per_tol_venda.isRequired}
                setInvalid={formRegraEmpresa.per_tol_venda.isInvalid}
                iniInicializado={inicializado}
                value={formRegraEmpresa.per_tol_venda.value}
              />
            </Col>
            <Col sm={12}>
              <InputPercent
                label="% Tolerância Até Emitir Bloqueio"
                casas=".01"
                placeholder="0,00%"
                onChange={(value: string) => {
                  formTela.setValue('per_bloq_venda', value?.replace('-', ''));
                  handleFormRegraEmpresa({
                    ...formRegraEmpresa,
                    per_bloq_venda: {
                      ...formRegraEmpresa.per_bloq_venda,
                      value: value?.replace('-', ''),
                      isInvalid: transformAsCurrency(value) === 0,
                    },
                  });
                }}
                isRequired={formRegraEmpresa.per_bloq_venda.isRequired}
                setInvalid={formRegraEmpresa.per_bloq_venda.isInvalid}
                iniInicializado={inicializado}
                value={formRegraEmpresa.per_bloq_venda.value}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          <Separator labelText="Vincular Status PDV ao Inativar Cliente:" />
          <Col sm={12}>
            <InputSelect
              label="Status PDV"
              onChange={(value: Select) => {
                formTela.setValue('cod_status_pdv', value);
                handleFormRegraEmpresa({
                  ...formRegraEmpresa,
                  cod_status_pdv: {
                    ...formRegraEmpresa.cod_status_pdv,
                    value,
                    isInvalid: value.value === undefined,
                  },
                });
              }}
              options={codStatusPdvs}
              isRequired={formRegraEmpresa.cod_status_pdv.isRequired}
              setInvalid={formRegraEmpresa.cod_status_pdv.isInvalid}
              iniInicializado={inicializado}
              value={
                codStatusPdvs.find((item: Select) => {
                  return (
                    item.value === formRegraEmpresa.cod_status_pdv.value.value
                  );
                }) ?? {
                  value: undefined,
                  label: '- Selecione o Status -',
                }
              }
            />
          </Col>
        </Col>
      </Row>
      <Row>
        <LimiteCredito />
      </Row>
    </>
  );
};
